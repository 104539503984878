import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from 'src/app/_services/admin.service';
import Swal from 'sweetalert2';
import { ReplyDialogComponent } from './reply-dialog/reply-dialog.component';

export interface PeriodicElement {
  Requestno: string;
  user: string;
  Service: any;
  RECEIVERSNAME: any;
  phoneno: any;
  FAMILY: any;
  date: any;
  Status: string;
  Action: any;
  image: any;
  OriginImg: any;
  origin: any;
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {
  statusOption = new FormControl('');
  serviceControl = new FormControl('');

  status: string[] = ['open', 'closed', 'invalid'];
  // language: any = [
  //   'English',
  //   'Hindi',
  //   'Telugu',
  //   'Tamil',
  //   'Malyalam',
  //   'Telugu',
  //   'Gujarati',
  //   'Punjabi',
  // ];
  Origin: any = [
    { key: 'general', value: 'General' },
    { key: 'website', value: 'Website' },
    { key: 'iOS', value: 'iOS' },
    { key: 'android', value: 'Android' },
  ];
  total: any;
  open: any;
  invalid: any;
  closed: any;
  statusMessage: any;
  queryParamsDetails: any;
  statusCompleted: any = 'closed';
  userDetailsParams: string;
  queryParamsStatus: any;
  onLoadPage = new EventEmitter();
  statusInvalid: any = 'invalid';
  totalRows: any;
  pageSize: any = 10;
  currentPage: any;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  // pageSizeOptions: number[] = [100, 200, 400, 1000];
  pageLimit: any;
  pageNumber: any;
  percentageparams: any;
  origin: any;
  queryParams: any;
  statusClear: string;
  originClear: string;
  dateClear: any;
  dateClear1: any;
  search: any;
  fromDate: any;
  toDate: any;
  calendraDate: string;
  dropDownSelection: any;
  Date: any;
  searchClear: string;
  statusParams: any;
  originValue: string;
  searchparams: any;
  permissons: string;
  permissionsArray: any;
  loader: boolean = true;
  data: string;
  statusLoader: boolean;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private services: AdminService
  ) {
    console.log('====================================');
    console.log('logged procccesed');
    console.log('====================================');
  }

  @ViewChild(MatPaginator) paginator: any = MatPaginator;
  ELEMENT_DATA: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA);
  ngOnInit(): void {
    this.services.refreshData$.subscribe(() => {
      // Handle data refresh logic here
      this.data = 'Refreshed Data';
    });
    this.loader = true;
    this.queryParams = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.support(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.loader = false;

      console.log(this.ELEMENT_DATA);
      this.total = response.supportRequest;
      this.open = response.open;
      this.invalid = response.invalidRequest;
      this.closed = response.closed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
    });
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    this.queryParams = {
      name: this.search ? this.search : '',
      status: this.statusParams ? this.statusParams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage ? newCurrentPage : '',
    };
    this.services.support(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.total = response.supportRequest;
      this.open = response.open;
      this.invalid = response.invalidRequest;
      this.closed = response.closed;
      console.log(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  displayedColumns: string[] = [
    'Requestno',
    'user',
    'Service',
    'help',
    'RECEIVERSNAME',
    'email',
    'mobileNumber',
    'locationName',
    'date',
    'Status',
    'Action',
  ];
  // dataSource = new MatTableDataSource(ELEMENT_DATA);
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }

  searchDropDown(event: any) {
    if (event.target.value != '') {
      this.search = event.target.value;
    } else {
      this.search = '';
    }
    this.queryParams = {
      name: this.search ? this.search : '',
      status: this.statusParams ? this.statusParams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.support(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      console.log(this.ELEMENT_DATA);
      this.total = response.supportRequest;
      this.open = response.open;
      this.invalid = response.invalidRequest;
      this.closed = response.closed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  statusDropDown(event: any) {
    this.statusLoader = true;
    if (event.value != '') {
      this.statusParams = JSON.stringify(event.value);
    } else {
      this.statusParams = '';
    }
    this.queryParams = {
      name: this.search ? this.search : '',
      status: this.statusParams ? this.statusParams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.support(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      this.statusLoader = false;

      console.log(this.ELEMENT_DATA);
      this.total = response.supportRequest;
      this.open = response.open;
      this.invalid = response.invalidRequest;
      this.closed = response.closed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  view() {
    const dialogRef = this.dialog.open(ReplyDialogComponent, {
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (result === 'refresh') {
        this.services.triggerDataRefresh();
      }
    });
  }
  getSupportRequest() {
    this.queryParams = {
      name: this.search ? this.search : '',
      status: this.statusParams ? this.statusParams : '',
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };
    this.services.support(this.queryParams).subscribe((response) => {
      this.ELEMENT_DATA = response.data;
      console.log(this.ELEMENT_DATA);
      this.total = response.supportRequest;
      this.open = response.open;
      this.invalid = response.invalidRequest;
      this.closed = response.closed;
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    });
  }
  markAsClose(id: any) {
    this.queryParamsStatus = {
      status: this.statusCompleted ? this.statusCompleted : '',
    };
    this.services
      .supportUpdate(id, this.queryParamsStatus)
      .subscribe((response) => {
        this.statusMessage = response.message;
        Swal.fire({
          text: 'closed succefully',
          showConfirmButton: true, // Hide the "OK" button
          icon: 'success', // You can change the icon type
          timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        });
        this.getSupportRequest();
        // this.reset();
        this.onLoadPage.emit();
      });
  }
  markAsInvalid(id: any) {
    this.queryParamsStatus = {
      status: this.statusInvalid ? this.statusInvalid : '',
    };
    this.services
      .supportUpdate(id, this.queryParamsStatus)
      .subscribe((response) => {
        Swal.fire({
          text: 'invalid succefully',
          showConfirmButton: true, // Hide the "OK" button
          icon: 'success', // You can change the icon type
          timer: 2000, // Set the timer to 3000 milliseconds (3 seconds)
        });
        this.statusMessage = response.message;
        this.getSupportRequest();
        // this.reset();
        this.onLoadPage.emit();
      });
  }
}
