
<div class="ml-3 mt-4">
    <h3>Instagram followers</h3>
</div>
<div class="d-flex search_view">
        <div>
                <img src="../../../assets/images/search.png">

                </div>
                <div class="">
                <input  (input)="applyFilter($event)" placeholder="Search by location name">

                </div>
                
            </div>
<div class="table-data mb-5 pb-3 mt-4">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> Location Name </th>
    <td mat-cell *matCellDef="let element">
      <p class="Id"> {{element.locations.name}}</p>  </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> Instagram URL </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element.locations.instagramId}}</p></td>
  </ng-container>
  <ng-container matColumnDef="Cname4">
    <th mat-header-cell *matHeaderCellDef> Embed URL </th>
    <td mat-cell *matCellDef="let element">
      
      <div class="">
   <p >{{element.locations.embed_url}} <span class="ml-4 updateF">
   <a class="cursor" (click)="addFollowers(element.locations._id,element.locations.embed_url,element.locations.instagramId)">edit</a>

        </span></p>
        
        </div>
    
    
    </td>
  </ng-container>
  <!-- <ng-container matColumnDef="Cname5">
    <th mat-header-cell *matHeaderCellDef> follow URL </th>
    <td mat-cell *matCellDef="let element">
      
      
    
     <div class="">
   <p >{{element.locations.follow_url}}   <span class="ml-4 updateF">
   <a class="cursor" (click)="addFollowers(element.locations._id,element.locations.embed_url,element.locations.instagramId)">edit</a>

        </span></p>
      
        </div>
    </td>
  </ng-container> -->
  <ng-container matColumnDef="Cname1">
    <th mat-header-cell *matHeaderCellDef> Exisiting on sign up

<br>DD/MM/YYYY </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element.locations.followers}}</p></td>
  </ng-container>
  <ng-container matColumnDef="Cname2">
    <th mat-header-cell *matHeaderCellDef> Current
 Followers </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="d-flex ml-4">
   <p >{{element.locations.current_followers}}</p>
        <span class="ml-2 mr-5 updateF">
   <a class="cursor" (click)="updateFollowers(element.locations._id,element.locations.current_followers,element.locations.instagramId)">Update</a>

        </span>
        </div>
     
    
    </td>
  </ng-container>
  <ng-container matColumnDef="Cname3">
    <th mat-header-cell *matHeaderCellDef>Total followers <br>
gained</th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element.locations.followersGained}}</p></td>
  </ng-container>

 
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class="action cursor">view </div></td>
  </ng-container>


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator [length]="totalSummary" (page)="pageChanged($event)"  [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> 
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> -->
     </div>