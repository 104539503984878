import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SidenavbarComponent } from './components/sidenavbar/sidenavbar.component';
import { CustomersComponent } from './components/customers/customers.component';
import { ReviewsComponent } from './components/reviews/reviews.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { VisitorsComponent } from './components/visitors/visitors.component';
import { SidenavheaderComponent } from './components/sidenavbar/sidenavheader/sidenavheader.component';
import { FreetrailsComponent } from './components/freetrails/freetrails.component';
import { SubscriptionComponent } from './components/subscription/subscription.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { LoaderComponent } from './components/loader/loader.component';
import { InternalTeamComponent } from './components/internal-team/internal-team.component';
import { AvatarModule } from 'ngx-avatar';
import { CreateadminComponent } from './components/internal-team/createadmin/createadmin.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UpdateUserComponent } from './components/internal-team/update-user/update-user.component';
import { TokenInterceptor } from './_services/token.interceptor';
import { SupportComponent } from './components/support/support.component';
import { ReplyDialogComponent } from './components/support/reply-dialog/reply-dialog.component';
import { SettingsComponent } from './components/settings/settings.component';
import { RefferalsComponent } from './components/refeerals/refferals/refferals.component';
import { LeadsComponent } from './components/refeerals/leads/leads.component';
import { ActivationComponent } from './components/refeerals/activation/activation.component';
import { CancelationComponent } from './components/cancelation/cancelation.component';
import { PushnotificationComponent } from './components/pushnotification/pushnotification.component';
import { ModificationsComponent } from './components/modifications/modifications.component';
import { LivefeedComponent } from './components/liveFeed/livefeed/livefeed.component';
import { ReferalsComponent } from './components/liveFeed/referals/referals.component';
import { StaffComponent } from './components/liveFeed/staff/staff.component';
import { LoginsComponent } from './components/liveFeed/logins/logins.component';
import { ReviewComponent } from './components/liveFeed/review/review.component';
import { OperationsComponent } from './components/operations/operations.component';
import { TranscationsComponent } from './components/transcations/transcations.component';
import { RevnueComponent } from './components/revnue/revnue.component';
import { TrackingDialogComponent } from './components/customers/tracking-dialog/tracking-dialog.component';
import { LocationsComponent } from './components/locations/locations.component';
import { MerchantsComponent } from './components/merchants/merchants.component';
import { LocationReviewsComponent } from './components/locations/location-reviews/location-reviews.component';
import { StarRatingModule } from 'angular-star-rating';
import { ReportsComponent } from './components/reports/reports.component';
import { ViewReviewsComponent } from './components/locations/view-reviews/view-reviews.component';
import { SmsanalyticsComponent } from './components/smsanalytics/smsanalytics.component';
import { SmsanalyticsModelComponent } from './components/smsanalytics/smsanalytics-model/smsanalytics-model.component';
import { TestingComponent } from './components/testing/testing.component';
import { SmsreqComponent } from './components/testing/smsreq/smsreq.component';
import { RefComponent } from './components/testing/ref/ref.component';
import { RevComponent } from './components/testing/rev/rev.component';
import { CusComponent } from './components/testing/cus/cus.component';
import { DatePipe } from '@angular/common';
import { RefdialogComponent } from './components/testing/ref/refdialog/refdialog.component';
import { ReviewscampinsdialogComponent } from './components/reviews/reviewscampinsdialog/reviewscampinsdialog.component';
import { RefferalcampinsdialogComponent } from './components/refeerals/refferals/refferalcampinsdialog/refferalcampinsdialog.component';
import { FreetrailcampinsdialogComponent } from './components/freetrails/freetrailcampinsdialog/freetrailcampinsdialog.component';
import { WeeklyreviewsComponent } from './components/reviews/weeklyreviews/weeklyreviews.component';
import { WeeklyreviewscampinsdialogComponent } from './components/reviews/weeklyreviewscampinsdialog/weeklyreviewscampinsdialog.component';
import { SplitPipe } from './_helpers/split.pipe';
import { InstagramfollowersComponent } from './components/instagramfollowers/instagramfollowers.component';
import { UpdatefollowersdialogComponent } from './components/instagramfollowers/updatefollowersdialog/updatefollowersdialog.component';
import { AddfollowersdialogComponent } from './components/instagramfollowers/addfollowersdialog/addfollowersdialog.component';
import { NamesdialogComponent } from './components/locations/namesdialog/namesdialog.component';
import { RefferalmsgComponent } from './components/refferalmsg/refferalmsg.component';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SidenavbarComponent,
    CustomersComponent,
    ReviewsComponent,
    VisitorsComponent,
    SidenavheaderComponent,
    FreetrailsComponent,
    SubscriptionComponent,
    LoaderComponent,
    InternalTeamComponent,
    CreateadminComponent,
    UpdateUserComponent,
    SupportComponent,
    ReplyDialogComponent,
    SettingsComponent,
    RefferalsComponent,
    LeadsComponent,
    ActivationComponent,
    CancelationComponent,
    PushnotificationComponent,
    ModificationsComponent,
    LivefeedComponent,
    ReferalsComponent,
    StaffComponent,
    LoginsComponent,
    ReviewComponent,
    OperationsComponent,
    TranscationsComponent,
    RevnueComponent,
    TrackingDialogComponent,
    LocationsComponent,
    MerchantsComponent,
    LocationReviewsComponent,
    ReportsComponent,
    ViewReviewsComponent,
    SmsanalyticsComponent,
    SmsanalyticsModelComponent,
    TestingComponent,
    SmsreqComponent,
    RefComponent,
    RevComponent,
    CusComponent,
    RefdialogComponent,
    ReviewscampinsdialogComponent,
    RefferalcampinsdialogComponent,
    FreetrailcampinsdialogComponent,
    WeeklyreviewsComponent,
    WeeklyreviewscampinsdialogComponent,
    SplitPipe,
    InstagramfollowersComponent,
    UpdatefollowersdialogComponent,
    AddfollowersdialogComponent,
    NamesdialogComponent,
    RefferalmsgComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    HighchartsChartModule,
    HttpClientModule,
    AvatarModule,
    ReactiveFormsModule,
    FormsModule,
    StarRatingModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
