
<div class="ml-3 mt-4">
    <h3>Refferal Categorys Message Change</h3>
</div>


<div class="d-flex ml-4 mt-5">
    <div>
        <p>Categorys</p>
        <select [(ngModel)]="selectedItem" (change)="categoryChange(selectedItem)">

            <option value="">Please select</option>
            <option *ngFor="let c of categorysData" [ngValue]="c">{{c.category}}</option>
        </select>

        <h3 class="mt-5">Variables names</h3>
          <ul>
            <li>locationName</li>
            <li>welcomeOffer</li>
            <li>cityName</li>
          </ul>
    </div>
    <div class="ml-5">
        <p>Text</p>
        <p *ngIf="text">Now you can edit</p>

          <textarea type="text" class="form-control" [(ngModel)]="categoryText" placeholder="Enter text" [readOnly]="readonly">
<!-- The more  stronger and healthier your teeth are the more food you can enjoy in the long term.It starts with taking care of your dental hygiene  at {locationName}.We are waiting for your first with. Don’t forget to claim  {welcomeOffer}% OFF on your first checkup.Book your first appointment today; -->
          </textarea>
    </div>

     <div class="mt-5 anchor pt-5 ml-5">
        <a (click)="edit()">edit</a>
     </div>
     <div class="mt-5 anchor pt-5 ml-5">
        <a (click)="update()">Update</a>
     </div>
</div>