import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as Highcharts from 'highcharts';
import { AdminService } from 'src/app/_services/admin.service';
export interface PeriodicElement {
  Cid: any;
  Cname: any;
  ctype: any;
  Locations: any;
  singupdate: any;
  freetrail: any;
  subscription: any;
  status: any;
  clicks: any;
  leads: any;
  firstvisits: any;
  conversion: any;
  Revenue: any;
  action: any;
}

@Component({
  selector: 'app-leads',
  templateUrl: './leads.component.html',
  styleUrls: ['./leads.component.css'],
})
export class LeadsComponent implements OnInit {
  loading: boolean;
  displayedColumns: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Locations',
    'singupdate',
    'freetrail',
    'subscription',
    'status',
    'clicks',
    'leads',
    'firstvisits',
    'conversion',
    'Revenue',

    'action',
  ];
  displayedColumns1: string[] = [
    'Cid',
    'Cname',
    'ctype',
    'Locations',
    'singupdate',
    'freetrail',
    'subscription',
    'status',
    'clicks',
    'leads',
    'firstvisits',
    'action',
  ];
  Language: any = ['This month', 'This week', 'Today'];
  loader: boolean = false;
  userQuery!: {};
  language: any = true;
  ELEMENT_DATA: Element[] = [];
  pageSize: any = 10;
  currentPage: any;
  unchecked: boolean = false;
  checked: boolean = true;
  pageSizeOptions: number[] = [10, 20, 40, 100];
  totalSummary: any;
  newUser: any;
  userDataCount: any;
  items: any[] = [];
  ELEMENT_DATA1: Element[] = [];
  dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
  dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
  userDelete: boolean = false;
  leads: any;

  constructor(private service: AdminService) {}

  ngOnInit(): void {
    this.userApisList();
  }
  userApisList() {
    this.loading = true;

    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: this.currentPage ? this.currentPage : '',
    };

    this.service.referralLeads(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.data.leads;
      this.ELEMENT_DATA1 = res.data.leadsTableData;
      this.loading = false;

      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      this.totalSummary = res.data.totalLeads;
      this.items = [
        { name: 'Total SMSsent', number: res.data.totalSMSsent },
        { name: 'Total Clicks', number: res.data.totalSmsClicks },
        { name: 'Total Leads', number: res.data.totalLeads },
        { name: 'Total FirstVisits', number: res.data.totalFirstVisits },
      ];
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(filterValue);
    this.loading = true;

    this.userQuery = {
      name: filterValue,
    };
    this.service.referralLeads(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.data.leads;
      this.leads = res.data.leads;
      this.ELEMENT_DATA1 = res.data.leadsTableData;
      this.loading = false;

      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      this.totalSummary = res.data.totalLeads;
      this.items = [
        { name: 'Total SMSsent', number: res.data.totalSMSsent },
        { name: 'Total Clicks', number: res.data.totalSmsClicks },
        { name: 'Total Leads', number: res.data.totalLeads },
        { name: 'Total FirstVisits', number: res.data.totalFirstVisits },
      ];
    });
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;

    this.currentPage = event.pageIndex;
    let newCurrentPage = event.pageIndex + 1;
    console.log(this.currentPage);
    console.log(this.pageSize);
    this.loading = true;
    this.userQuery = {
      pageLimit: this.pageSize ? this.pageSize : '',
      pageNumber: newCurrentPage,
    };
    this.service.referralLeads(this.userQuery).subscribe((res) => {
      console.log(res);
      this.ELEMENT_DATA = res.data.leads;
      this.ELEMENT_DATA1 = res.data.leadsTableData;
      this.loading = false;

      this.dataSource1 = new MatTableDataSource(this.ELEMENT_DATA);
      // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA1);
      this.totalSummary = res.data.totalLeads;
      this.items = [
        { name: 'Total SMSsent', number: res.data.totalSMSsent },
        { name: 'Total Clicks', number: res.data.totalSmsClicks },
        { name: 'Total Leads', number: res.data.totalLeads },
        { name: 'Total FirstVisits', number: res.data.totalFirstVisits },
      ];
    });
  }
}
