

<div class="sidenavbar-heade">
<!-- <div class='row'>
    <div class="col-md-2">
    <app-sidenavbar></app-sidenavbar>

    </div> -->
    <div class="summary-header ml-3 mt-md-3">
     <div class="d-flex">
        <div class="customer_header mr-auto">
            <h1>Sms Analytics</h1>
        </div>
            <app-loader *ngIf="loader"></app-loader>

       <!-- <div class="d-flex   language-header">
       
            <select   class="selectpicker" >
                <option  *ngFor="let item of Language" [selected]="item == 'This month'" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
     </div>

     <div class="card-section my-md-4 d-flex justify-content-between">
        <div class="card mr-md-5" *ngFor="let item of items">
            <div class="card-body">
                <p>{{item.number}}</p>

                <h3>{{item.name}}</h3>
            </div>
        </div>
     </div>
     <!-- <div class="d-flex search_view">
        <div>
                <img src="../../../assets/images/search.png">

                </div>
                <div class="">
                <input  (input)="applyFilter($event)" placeholder="Search by location name">

                </div>
                
            </div> -->
     <!-- <div class="d-flex justify-content-end mr-md-5 mb-3">
       
            <select (change)="langugeChange($event)"  class="selectpicker" >
              <option value="">select month</option>
                <option  *ngFor="let item of monthsArray" [selected]="item == this.currentMonth" value="{{item}}">{{item}}</option>
            </select>
        </div> -->
        <!-- <div><h2>Merchant Subscriptions</h2>
             </div> -->

         <div class="table-data mb-5 pb-3 mt-4">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!-- Weight Column -->
  <ng-container matColumnDef="ctype">
    <th mat-header-cell *matHeaderCellDef> LocationName </th>
    <td mat-cell *matCellDef="let element"><p class="Id">{{element?.locationName}}</p></td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="Locations">
    <th mat-header-cell *matHeaderCellDef> Locations_id </th>
    <td mat-cell *matCellDef="let element"> {{element?.locationId}} </td>
  </ng-container>
   <ng-container matColumnDef="freetrail1">
    <th mat-header-cell *matHeaderCellDef> smsSent </th>
    <td mat-cell *matCellDef="let element"> {{element?.smsSent}} </td>
  </ng-container>
    <ng-container matColumnDef="Cid">
    <th mat-header-cell *matHeaderCellDef> clicks </th>
    <td mat-cell *matCellDef="let element">
      <p class="Id"> {{element.clicks}}</p>  </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="Cname">
    <th mat-header-cell *matHeaderCellDef> Reviews_sms </th>
    <td mat-cell *matCellDef="let element"> <p class="Id">{{element?.delivered}}</p></td>
  </ng-container>
 <ng-container matColumnDef="freetrail">
    <th mat-header-cell *matHeaderCellDef> Referrals_sms</th>
    <td mat-cell *matCellDef="let element"> {{element?.open}} </td>
  </ng-container>

 <ng-container matColumnDef="freetrail2">
    <th mat-header-cell *matHeaderCellDef> Type </th>
    <td mat-cell *matCellDef="let element"> {{element?.type ? element?.type : 'N/A'}} </td>
  </ng-container>
 <ng-container matColumnDef="review">
    <th mat-header-cell *matHeaderCellDef> Add </th>
    <td mat-cell *matCellDef="let element"> 
    <button class="cursor add" (click)="addReview(element?.locationId)">Add</button>
    </td>
  </ng-container>
   
  <!-- <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <td mat-cell *matCellDef="let element"> 
        <div class=" cursor">
    <button class="cursor add" (click)="view(element?.locations[0]?.locationId)">View reviews</button>

        </div></td>
  </ng-container> -->


  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
<mat-paginator (page)="pageChanged($event)" [length]="totalSummary" [pageIndex]="currentPage" [pageSize]="pageSize"  [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users"></mat-paginator> 
  <!-- <p class="no-user" *ngIf="userData == ''">No users in {{language}} language</p>
-->
     </div>
 
      </div>
      </div>