import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private refreshDataSubject = new Subject<void>();

  constructor(private http: HttpClient, private router: Router) {}
  getToken() {
    return localStorage.getItem('token') || '';
  }

  refreshData$ = this.refreshDataSubject.asObservable();
  private refreshSubject$ = new BehaviorSubject<boolean>(false);
  getRefreshSignal() {
    return this.refreshSubject$.asObservable();
  }
  refresh() {
    this.refreshSubject$.next(true);
  }

  triggerDataRefresh() {
    this.refreshDataSubject.next();
  }
  //admin-login
  login(data: any) {
    return this.http.post<any>(`${environment.APIURL}admin/login`, data).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
  logOut() {
    if (localStorage.getItem('token') != null) {
      localStorage.clear();
      this.router.navigate(['/admin/login']);
    }
  }
  locationsNames() {
    const url = `${environment.APIURL}testing/location-name`;
    return this.http.get<any>(url);
  }
  generateSms(data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}testing/generate-sms`;
    return this.http.post<any>(url, data);
  }
  generateLeads(data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}testing/generate-leads`;
    return this.http.post<any>(url, data);
  }
  generateCustomers(data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}testing/generate-customers`;
    return this.http.post<any>(url, data);
  }
  updateLeads(id: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}testing/update/leads?leadsId=${id}`;
    return this.http.put<any>(url, httpOptions);
  }
  campignReviews(data: any) {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}compaign/reviews`;
    return this.http.post<any>(url, data);
  }
  campignReviewsGrowth(data: any) {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}compaign/reviews-growth`;
    return this.http.post<any>(url, data);
  }
  campignFreeTrail(data: any) {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}compaign/free-trail`;
    return this.http.post<any>(url, data);
  }
  campignRefferal(data: any) {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}compaign/referrals`;
    return this.http.post<any>(url, data);
  }
  updateThreshold(id: any, number: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}testing/update/referrals?referralProgramId=${id}&referrer_threshold=${number}`;
    return this.http.put<any>(url, httpOptions);
  }
  customersData(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}testing/customer-data`;
    return this.http.get<any>(url, httpOptions);
  }
  leadsData(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}testing/leads-data`;
    return this.http.get<any>(url, httpOptions);
  }
  referredData(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}testing//referred-data`;
    return this.http.get<any>(url, httpOptions);
  }
  customers(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/analytics`;
    return this.http.get<any>(url, httpOptions);
  }
  reviews(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}/reviews`;
    return this.http.get<any>(url, httpOptions);
  }
  reviewsGrowth(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}/reviews/growth`;
    return this.http.get<any>(url, httpOptions);
  }
  MerchantInstagram(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}/customer/merchant-instagaram/data`;
    return this.http.get<any>(url, httpOptions);
  }
  updatefollowers(id: any, count: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}customer/merchant-instagaram/update-followers?locationId=${id}&current_followers=${count}`;
    return this.http.put<any>(url, httpOptions);
  }
  updateurls(id: any, embeed: any, followurl: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}customer/merchant-instagaram/update-reditUrl?locationId=${id}&embed_url=${embeed}&follow_url=${followurl}`;
    return this.http.put<any>(url, httpOptions);
  }
  updateSmsName(id: any, embeed: any, followurl: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}customer/merchant-locations/update?locationId=${id}&smsLocationName=${embeed}&displayLocationName=${followurl}`;
    return this.http.put<any>(url, httpOptions);
  }
  updateCategorys(data: any) {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}customer/update-category`;
    return this.http.put<any>(url, data);
  }
  categorys() {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}customer/category-data`;
    return this.http.get<any>(url);
  }
  visitor(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}visitor`;
    return this.http.get<any>(url, httpOptions);
  }
  freeTrail(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/freetrail/cancelled`;
    return this.http.get<any>(url, httpOptions);
  }
  internalTeam(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}user/user-data`;
    return this.http.get<any>(url, httpOptions);
  }
  subsribers(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/subscription`;
    return this.http.get<any>(url, httpOptions);
  }
  cancellation(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/subscription/cancelled`;
    return this.http.get<any>(url, httpOptions);
  }
  referralLeads(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}referral/dashboard/referral-leads`;
    return this.http.get<any>(url, httpOptions);
  }
  referralActivations(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}referral/dashboard/referral-activation`;
    return this.http.get<any>(url, httpOptions);
  }
  referrals(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}referral/dashboard/referral-data`;
    return this.http.get<any>(url, httpOptions);
  }
  support(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}user/support`;
    return this.http.get<any>(url, httpOptions);
  }
  merchant(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/merchant-data`;
    return this.http.get<any>(url, httpOptions);
  }
  locations(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/merchant-locations`;
    return this.http.get<any>(url, httpOptions);
  }
  reviewsManual(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/reviews-data`;
    return this.http.get<any>(url, httpOptions);
  }
  smsId(id: any) {
    const url = `${environment.APIURL}customer/sms-data?locationId=${id}
`;
    return this.http.get<any>(url);
  }
  sms(params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}customer/sms-data
`;
    return this.http.get<any>(url, httpOptions);
  }
  supportUpdate(id: any, params: any) {
    const httpOptions = {
      params: params,
    };
    const url = `${environment.APIURL}user/support/update/${id}`;
    return this.http.put<any>(url, params, httpOptions);
  }
  internalTeamData(data: any) {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}user`;
    return this.http.post<any>(url, data);
  }
  smsAnalytics(data: any) {
    // const httpOptions = {
    //   params: params,
    // };
    const url = `${environment.APIURL}customer/sms-analytics`;
    return this.http.post<any>(url, data);
  }
  updateUser(data: any, id: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}user/update-user/` + id;
    return this.http.put<any>(url, data, httpOptions);
  }
  supportRequestDetails(id: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}user/support-details/` + id;
    return this.http.get<any>(url);
  }
  adminDetails() {
    // const httpOptions = {};
    const url = `${environment.APIURL}admin/token-details`;
    return this.http.get<any>(url);
  }

  supportChangeStatus(id: any, data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}user/send-reply/` + id;
    return this.http.post<any>(url, data);
  }
  addReviews(data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}customer/reviews`;
    return this.http.post<any>(url, data);
  }
  suspendedUser(id: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}user/update-status/` + id;
    return this.http.put<any>(url, httpOptions);
  }
  adminUpdateProfile(data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/profile-update`;
    return this.http.put<any>(url, data, httpOptions);
  }
  adminChangePassword(data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/change-password`;
    return this.http.put<any>(url, data, httpOptions);
  }

  updatefreetrail(day: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/update-freeTrails?name=freeTrail&days=${day}`;
    return this.http.put<any>(url, httpOptions);
  }
  tracking(location: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}customer/tracking?locationId=${location}`;
    return this.http.get<any>(url, httpOptions);
  }
  updateSubs(c: any, p: any, city: any, t: any, d: any) {
    const httpOptions = {
      // params: params,
    };
    const url = `${environment.APIURL}admin/update-subscription?name=subscription&category=${c}&price=${p}/Day&city=${city}&type=${t}&discount=${d}`;
    return this.http.put<any>(url, httpOptions);
  }
  cityOptions() {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/indian-city`;
    return this.http.get<any>(url, httpOptions);
  }
  revenue() {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/merchant-revenue`;
    return this.http.get<any>(url, httpOptions);
  }
  transcations() {
    const httpOptions = {};
    const url = `${environment.APIURL}transcations/data`;
    return this.http.get<any>(url, httpOptions);
  }
  subData() {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/subscription-data`;
    return this.http.get<any>(url, httpOptions);
  }
  reviewOptions() {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/review`;
    return this.http.get<any>(url, httpOptions);
  }
  categoryOptions() {
    const httpOptions = {};
    const url = `${environment.APIURL}admin/categories`;
    return this.http.get<any>(url, httpOptions);
  }
  liveFeed() {
    const httpOptions = {};
    const url = `${environment.APIURL}liveFeed/data`;
    return this.http.get<any>(url, httpOptions);
  }
  liveFeedRefferals() {
    const httpOptions = {};
    const url = `${environment.APIURL}liveFeed/referrals`;
    return this.http.get<any>(url, httpOptions);
  }
  liveFeedReview() {
    const httpOptions = {};
    const url = `${environment.APIURL}liveFeed/reviews`;
    return this.http.get<any>(url, httpOptions);
  }
  liveFeedLogins() {
    const httpOptions = {};
    const url = `${environment.APIURL}liveFeed/logins`;
    return this.http.get<any>(url, httpOptions);
  }
  liveFeedStaff() {
    const httpOptions = {};
    const url = `${environment.APIURL}liveFeed/staff`;
    return this.http.get<any>(url, httpOptions);
  }
  createNotifications(data: any) {
    const httpOptions = {};
    const url = `${environment.APIURL}compaign/push`;
    return this.http.post<any>(url, data);
  }
  // createNotifications(data: any) {
  //   const httpOptions = {};
  //   const url = `${environment.APIURL}admin/create-notification`;
  //   return this.http.post<any>(url, data);
  // }
}
